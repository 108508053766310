
const global = {
    "global": "FRANÇAIS",
    "multilangue": ["FRANÇAIS", "ENGLISH", "中国人"],
    "FRANÇAIS": {
        "Header": {
            "txt0-0": "NOTRE",
            "txt0-1": "LABORATOIRE",
            "txt1-0": "FORMULE EN",
            "txt1-1": "MARQUE BLANCHE",
            "txt2-0": "FORMULE",
            "txt2-1": "SUR-MESURE",
            "txt3-0": "QUALITÉ",
            "txt4-0": "CONTACT"

        },
        "Footer": {
            "txt0-0": " 145 RUE DE LA MARBRERIE",
            "txt0-1": "LE SALAISON",
            "txt0-2": "Bât.12 ",
            "txt0-3": "145 RUE DE LA MARBRERIE",
            "txt0-4": "34740 VENDARGUES",
            "txt0-5": "+33 4 99 78 08 11",
            "txt1-0": "CONTACTEZ-NOUS",
            "txt1-1": "Sujet",
            "txt1-2": "Nom",
            "txt1-3": "Prénom",
            "txt1-4": "Société",
            "txt1-5": "Pays",
            "txt1-6": "Téléphone",
            "txt1-7": "Email",
            "txt1-8": "Message",
            "txt1-9": "ENVOYER"
        },
        "HomePage": {
            "txt0-0": "NOTRE SAVOIR-FAIRE  ",
            "txt0-1": "LA CRÉATION ET LE FAÇONNAGE ",
            "txt0-2": "DE VOS COMPLÉMENTS ALIMENTAIRES",
            "txt1-0": "Notre objectif principal est de proposer une large gamme de compléments alimentaires afin de répondre aux besoins de santé et de prévention de chacun avec une qualité irréprochable permettant de garantir l’efficacité des produits.",
            "txt1-1": "Nous concevons nos produits sur l’alliance de deux éléments :",
            "txt1-2": "• Les bienfaits offerts par la Nature.",
            "txt1-3": "• Les avancées pertinentes de la Science.",
            "txt2-0": "Nous proposons une flexibilté en terme de production allant de petites à grandes séries, que ce soit en marque blanche ou en solutions sur-mesure.",
            "txt2-1": "La qualité de nos produits est garantie par notre gamme étendue de certifications.",
            "txt2-2": "Nous mettons à disposition un large éventail de formes galéniques, qu'il s'agisse de formes sèches, de gélules, de capsules ou de liquides, tous d'une qualité exceptionnelle.",
            "txt2-3": "Nos matières premières sont méticuleusement choisies pour satisfaire à toutes les exigences, et notre approche est respectueuse de l'environnement.",
            "txt3-0": "NOTRE MISSION",
            "txt3-1":"A l’écoute de vos besoins et des attentes du marché, nous créons ensemble vos produits adaptés pour atteindre vos objectifs commerciaux et votre réussite.",
            "txt4-0": "LABORATOIRE",
            "txt4-1": "FRANÇAIS",
            "txt4-2": "INDÉPENDANT",
            "txt4-3": "& AUTONOME",
            "txt4-4": "RECHERCHE &",
            "txt4-5": "DEVELOPPEMENT",
            "txt4-6": "+ DE 20 ANS",
            "txt4-7": "D'EXPÉRIENCE",
            "txt5-0": "FORMULATION",
            "txt5-1": "& SOURCING",
            "txt5-2": "EXPERTISE R&D",
            "txt5-3": "AU MARKETING",
            "txt5-4": "FAÇONNAGE",
            "txt5-5": "PETITES & GRANDES SERIES",
            "txt5-6": "CONDITIONNEMENT",
            "txt5-7": "OPTIMAL",
            "txt5-8": "FORMATION",
            "txt5-9": "& COMMUNICATION",
            "txt5-10": "DESIGN ",
            "txt5-11": "& PACKAGING",
            "txt5-12": "RÈGLEMENTATION ",
            "txt5-13": "& QUALITÉ",
            "txt6-0": "FORMULE",
            "txt6-1": "EN MARQUE",
            "txt6-2": "BLANCHE",
            "txt6-3": "Vous accédez à des produits clé en main, prêts à être mis sur le marché.",
            "txt6-4": "FORMULE",
            "txt6-5": "PERSONNALISÉE",
            "txt6-6": "SUR-MESURE",
            "txt6-7": "Vous développez vos formules selon votre cahier des charges."
        },
        "NotreLabo": {
            "txt0-0": "LE CRÉATEUR",
            "txt0-1": "DE VOS COMPLÉMENTS",
            "txt0-2": "ALIMENTAIRES",
            "txt1-0": "NOTRE HISTOIRE",
            "txt1-1": "Crée en 2010, LIN, le Laboratoire d’Innovation Naturelle est aujourd’hui l’un des acteurs incontournables dans le monde des compléments alimentaires innovants.",
            "txt1-2": "Nous disposons d'un laboratoire de recherche et développement, d'une installation de production, ainsi que d'un système de contrôle qualité qui assure la traçabilité des matières premières depuis leur réception jusqu'à la production des articles finis. Nous garantissons des produits de haute qualité contenant des ingrédients actifs hautement biodisponibles.",
            "txt1-3": "Une équipe composée de 40 professionnels est entièrement dédiée à cette division pour assurer la réalisation réussie de vos projets de fabrication.",
            "txt2-0": "NOS MÉTIERS",
            "txt3-0": "FORMULATION",
            "txt3-1": "& SOURCING",
            "txt3-2-0":"• Formulation d’ingrédients innovants",
            "txt3-2-1":"• Identification des actifs les plus performants",
            "txt3-2-2":"• Analyses d’études cliniques",
            "txt3-2-3":"• Sélection d’ingrédients rigoureusement choisis",
            "txt3-2-4":"• Exigence de technicité et d’efficacité", 
            "txt3-2-5":"• Formulation sur mesure ou clef en main.",
            "txt3-3": "RÈGLEMENTATION",
            "txt3-4": "& QUALITÉ",
            "txt3-5-0":"• Allégations santé",
            "txt3-5-1":"• Vérifications réglementaires des compléments alimentaires",
            "txt3-5-2":"• Validation de formules et packagings", 
            "txt3-5-3":"• Analyses de recommandations, méthodes et normes de qualité", 
            "txt3-5-4":"• Suivi et veille réglementaire",
            "txt3-5-5":"• Relation avec les autorités de contrôles (DGCCRF, ANSES, ANSM).",
            "txt3-6": "FAÇONNAGE &",
            "txt3-7": "CONDITIONNEMENT",
            "txt3-8-0":"• Outil de production complet adapté aux petites et grandes séries",
            "txt3-8-1":"• Micro encapsulation", 
            "txt3-8-2":"• Large choix de gamme de produits : phytothérapie, nutraceutique, probiotiques, bio…", 
            "txt3-8-3":"• Production en marque blanche grâce à notre catalogue dédié", 
            "txt3-8-4":"• Distribution en centre de répartition (palettes) ou directement en point de vente (colisage)",
            "txt3-9": "FORMATION &",
            "txt3-10": "COMMUNICATION",
            "txt3-11-0":"• Formation et communication", 
            "txt3-11-1":"• Élaboration d'outils de ventes pour les commerciaux",
            "txt3-11-2":"• Vente de produits à distance", 
            "txt3-11-3":"• Formation des équipes de ventes.",
            "txt4-0": "NOS MOYENS",
            "txt4-1": "Une flexibilité de production pour s’adapter à vos besoins :",
            "txt4-2": "des lots de 500 à 50.000 unités !",
            "txt5-0": "PRODUCTION",
            "txt5-1": "3 mélangeurs",
            "txt5-2": "— Capacité de 20 kilos à 1 tonne/jour —",
            "txt5-3": "6 géluleuses",
            "txt5-4": "— Capacité de 200.000 gélules/heure —",
            "txt5-5": "6 brosseuses de gélules",
            "txt5-6": "1 remplisseuse liquide",
            "txt5-7": "— De 10 à 30 ml —",
            "txt6-0": "CONDITIONNEMENT",
            "txt6-1": "1 ligne de mise en pilulier avec bouchage et étiquetage  pour flacons en verre, plastique ",
            "txt6-2": "— Capacité de 10.000 unités/jour —",
            "txt6-3": "1 ligne de blistérisation automatisée",
            "txt6-4": " — Capacité de 60.000 unités/jour —",
            "txt6-5": "2 étuyeuses automatiques",
            "txt7-0": "QUALITÉ",
            "txt7-1": "Contrôle qualité garanti",
            "txt7-2": "— Dispose de plusieurs certifications —",
            "txt7-3": "(BIO, HACCP, GMP FOOD…)",
            "txt7-4": "Projet norme ISO 22000",
            "txt7-5": "Depuis fin 2022, nous avons entrepris les démarches nécessaires pour l’obtention de la norme ISO 22000. "


        },
        "SurMesure": {
            "txt0-0": "NOS FORMULES",
            "txt0-1": "SUR-MESURE",
            "txt0-2": "VOTRE FORMULE",
            "txt0-3": "100% PERSONNALISÉE",
            "txt0-4": "VOTRE PACKAGING",
            "txt1-0": "Vos besoins sont uniques.",
            "txt1-1": "Vous souhaitez vous différencier sur le marché et développer vos formules selon votre cahier des charges ?",
            "txt1-2":"De la conception à la réalisation de votre produit, nous vous offrons toute notre expertise pour créer des formulations et des packagings entièrement personnalisés, parfaitement adaptés à votre public cible.",
            "txt1-3": "Nous mettons à disposition une variété étendue de produits élaborés par notre équipe de recherche et développement pour répondre aux divers besoins du marché.",
            "txt1-4": "Nous disposons de + de 700 matières premières référencées qui vous permettront d’innover et d’être avant-gardiste sur le marché.   vous proposons un accompagnement sur-mesure avec toute notre équipe  jusqu'au lancement de votre produit sur le marché.",
            "txt1-5":"Nous vous proposons un accompagnement sur-mesure jusqu'au lancement de votre produit sur le marché pour booster votre réussite. ",
            "txt2-0": "NOS PROCESSUS",
            "txt2-1": "DE FABRICATION",
            "txt3-0": "CAHIER",
            "txt3-1": "DES CHARGES",
            "txt3-2": "DÉVELOPPEMENT",
            "txt3-3": "PRODUCTION",
            "txt3-4": "CONDITIONNEMENT",
            "txt3-5": "PERSONNALISÉ",
            "txt3-6": "ENVOI",
            "txt3-7": "PRODUIT",
            "txt3-8": "PACKAGING",
            "txt3-9": "PERSONNALISÉ",
            "txt4-0": "NOS DIVERSES",
            "txt4-1": "FORMES GALÉNIQUES",
            "txt4-2": "POUDRE",
            "txt4-3": "Nous conditionnons nos poudres sur-mesure en fonction de vos besoins, qu'il s'agisse de plantes, de vitamines, de minéraux, en combinaison ou séparément.",
            "txt4-4": "GÉLULE",
            "txt4-5": "Nous offrons une gamme complète de tailles en version végétale (pullulan, HPMC...) ou gélatine.",
            "txt4-6": "CAPSULE",
            "txt4-7": "Nous proposons un large éventail d'opportunités, que ce soit dans le cadre de formules standards ou de développements sur-mesure.",
            "txt4-8": "LIQUIDE",
            "txt4-9": "Nous proposons également une gamme d’huile végétales ou huiles essentielles.",
            "txt5-0": "ET NOS CHOIX",
            "txt5-1": "DE CONDITIONNEMENT",
            "txt5-2": "(SOUS DIVERS FORMATS)",
            "txt5-3": "BLISTER",
            "txt5-4": "PILULIER",
            "txt5-5": "PIPETTE",
            "txt5-6": "POT",
            "txt6-0": "NOS",
            "txt6-1": "DOMAINES",
            "txt6-2": "D'APPLICATIONS",
            "txt7-0": "BEAUTÉ",
            "txt7-1": "BIO",
            "txt7-2": "DOULEURS",
            "txt7-3": "CIRCULATION",
            "txt7-4": "CONFORT URINAIRE",
            "txt7-5": "CONFORT & PREVENTION",
            "txt7-6": "CIRCULATION",
            "txt7-7": "LIPOSOME",
            "txt7-8": "CONFORT & PREVENTION",
            "txt7-9": "TONIQUES & VITAMINES",
            "txt7-10": "DIGESTION TRANSIT / DETOX",
            "txt7-11": "SOMMEIL & SERENITE",
            "txt7-12": "GAMME FEMME",
            "txt7-13": "VOIES RESPIRATOIRES ET IMMUNITE",
            "txt7-14": "DEMANDEZ",
            "txt7-15": "NOTRE CATALOGUE"
        },
        "MarqueBlanche": {
            "txt0-0": "NOS FORMULES",
            "txt0-1": "EN MARQUE",
            "txt0-2": "BLANCHE",
            "txt0-3": "NOTRE FORMULE",
            "txt0-4": "ISSUE DE",
            "txt0-5": "NOTRE CATALOGUE",
            "txt0-6": "VOTRE PACKAGING",
            "txt1-0": "Nous disposons d’un large choix de formules innovantes. Ces formulations ont été élaborées par nos équipes de recherche et développement afin de répondre aux divers besoins du marché.",
            "txt1-1": "Vous accédez à des produits clé en main, prêts à être mis sur le marché.",
            "txt1-2": "Le seuil minimum de commande pour cette offre est de 500 unités.",
            "txt1-3": "Ce service peut être complémentaire à nos services de développement sur-mesure, afin de garantir que votre marque conserve son caractère unique.",
            "txt2-0": "NOS PROCESSUS",
            "txt2-1": " DE FABRICATION ",
            "txt2-2": " CHOIX D'UNE ",
            "txt2-3": " FORMULE STANDARD ",
            "txt2-4": "PRODUCTION",
            "txt2-5": "CONDITIONNEMENT",
            "txt2-6": "PERSONNALISÉ",
            "txt2-7": "ENVOI",
            "txt2-8": "PRODUIT",
            "txt3-0": "NOS DIVERSES",
            "txt3-1": "FORMES GALÉNIQUES",
            "txt3-2": "POUDRE",
            "txt3-3": "Nous conditionnons nos poudres sur-mesure en fonction de vos besoins, qu'il s'agisse de plantes, de vitamines, de minéraux, en combinaison ou séparément.",
            "txt3-4": "GÉLULE",
            "txt3-5": "Nous offrons une gamme complète de tailles en version végétale (pullulan, HPMC...) ou gélatine.",
            "txt3-6": "CAPSULE",
            "txt3-7": "Nous proposons un large éventail d'opportunités, que ce soit dans le cadre de formules standards ou de développements sur-mesure.",
            "txt3-8": "LIQUIDE",
            "txt3-9": "Nous proposons également une gamme d’huile végétales ou huiles essentielles.",
            "txt4-0": "ET NOS CHOIX",
            "txt4-1": "DE CONDITIONNEMENT",
            "txt4-2": "(SOUS DIVERS FORMATS)",
            "txt4-3": "BLISTER",
            "txt4-4": "PILULIER",
            "txt4-5": "PIPETTE",
            "txt4-6": "POT",
            "txt5-0": "NOS",
            "txt5-1": "DOMAINES",
            "txt5-2": "D'APPLICATIONS",
            "txt6-0": "BEAUTÉ",
            "txt6-1": "BIO",
            "txt6-2": "DOULEURS",
            "txt6-3": "CIRCULATION",
            "txt6-4": "CONFORT URINAIRE",
            "txt6-5": "CONFORT & PREVENTION",
            "txt6-6": "CIRCULATION",
            "txt6-7": "LIPOSOME",
            "txt6-8": "CONFORT & PREVENTION",
            "txt6-9": "TONIQUES & VITAMINES",
            "txt6-10": "DIGESTION TRANSIT / DETOX",
            "txt6-11": "SOMMEIL & SERENITE",
            "txt6-12": "GAMME FEMME",
            "txt6-13": "VOIES RESPIRATOIRES ET IMMUNITE",
            "txt7": "Vous souhaitez créer et commercialiser de nouveaux produits avec des concepts déjà développés et approuvés sur le marché ?",
            "txt7-14": "DEMANDEZ",
            "txt7-15": "NOTRE CATALOGUE"
        },
        "Qualite": {
            "txt0-0": "QUALITÉ",
            "txt0-1": "ENGAGEMENT",
            "txt0-2": "CERTIFIÉS",
            "txt1-0": "Notre engagement qualité est permanent et primordial.",
            "txt1-1": "La sélection des matières selon notre cahier des charges défini est rigoureuse",
            "txt1-2": "Chaque produit fini est analysé selon un plan de contrôle établi pour vous donner l’opportunité de commercialiser vos produits à l’international.",
            "txt1-3": "Process de production établi en respectant les normes en vigueur",
            "txt1-4": "Qualité des matières premières garantie par la sélection de fournisseurs de confiance",
            "txt1-5": "Contrôle qualité à chaque étape de la production, assurant une traçabilité complète",
            "txt1-6": "Cahiers des charges rigoureusement respecté",
            "txt2-0": "RECHERCHE",
            "txt2-01": " &",
            "txt2-1": "DÉVELOPPEMENT",
            "txt2-2": "Aide à la création de formulations.",
            "txt2-3": "Recherche de matières premières performantes.",
            "txt2-4": "Présentation de diverses formulations possibles.",
            "txt2-5": "RÈGLEMENTAIRE",
            "txt2-6": "Notre équipe réglementaire interne s'assure de la conformité des produits en accord avec la législation française et européenne.",
            "txt2-7": "Assistance dans la rédaction des étiquettes.",
            "txt2-8": "Déclaration auprès de la DGAL (Direction Générale de l’Alimentation) et support technique en cas de vérification.",
            "txt2-9": "Enregistrement auprès de notre organisme certificateur pour les produits biologiques.",
            "txt2-10": "QUALITÉ",
            "txt2-11": "Garantie de la qualité et de la sécurité sanitaire des produits (via notre plan HACCP*)",
            "txt2-12": "Traçabilité complète des matières et des produits.",
            "txt2-13": "Respect rigoureux du cahier des charges",
            "txt2-14": "Contrôle effectué à chaque étape de la production.",
            "txt2-15": "Constitution de dossiers de lots.",
            "txt2-16": "* Les textes réglementaires sur lesquels s’appuie sur les règlements CE n°852/2004, CE n°178/2002, CE n°853/2004, le codex Alimentarius, code d’usage international recommandé."
        },
        "Other": {
            "txt0-0": "EN SAVOIR +"
        }, 
        "Contact":{
            "txt0-0":"Mail envoyé avec succès !",
            "txt0-1":"CONTACTEZ NOUS",
            "txt0-2":"Sujet",
            "txt0-3":"Nom",
            "txt0-4":"Société",
            "txt0-5":"Pays",
            "txt0-6":"Téléphone",
            "txt0-7":"Email",
            "txt0-8":"Votre demande",
            "txt0-9":"Envoyer",
            "txt0-10":"Sélectionnez un pays",
        }
    },
    "ENGLISH": {
        "Header": {
            "txt0-0": "OUR",
            "txt0-1": "LABORATORY",
            "txt1-0": "PRIVATE LABEL",
            "txt1-1": "FORMULA",
            "txt2-0": "CUSTOM",
            "txt2-1": "FORMULA",
            "txt3-0": "QUALITY",
            "txt4-0": "CONTACT",
        },
        "Footer": {
            "txt0-0": "145 RUE DE LA MARBRERIE",
            "txt0-1": "LE SALAISON",
            "txt0-2": "Bât.12",
            "txt0-3": "145 RUE DE LA MARBRERIE",
            "txt0-4": "34740 VENDARGUES",
            "txt0-5": "+33 4 99 78 08 11",
            "txt1-0": "CONTACT US",
            "txt1-1": "Subject",
            "txt1-2": "Name",
            "txt1-3": "First Name",
            "txt1-4": "Company",
            "txt1-5": "Country",
            "txt1-6": "Phone",
            "txt1-7": "Email",
            "txt1-8": "Message",
            "txt1-9": "SEND"
        },
        "HomePage": {
            "txt0-0": "OUR EXPERTISE ",
            "txt0-1": "CREATE AND SHAPE ",
            "txt0-2": "YOUR DIETARY SUPPLEMENTS",
            "txt1-0": "Our main goal is to offer a wide range of dietary supplements to meet the health and preventive needs of everyone with impeccable quality that guarantees the products effectiveness.",
            "txt1-1": "We design our products based on combination of two elements:",
            "txt1-2": "• The benefits offered by Nature.",
            "txt1-3": "• Relevant advances in Science.",
            "txt2-0": "We offer flexibility in terms of production, ranging from small to large series whether it's private label or customized solutions.",
            "txt2-1": "The quality of our products is guaranteed by our extensive range of certifications.",
            "txt2-2": "We provide a wide range of galenic forms whether it's dry forms, capsules, softgels or liquids, all of exceptional quality.",
            "txt2-3": "Our raw materials are meticulously selected to meet all requirements and our approach is environmentally friendly.",
            "txt3-0": "OUR MISSION",
            "txt3-1": "Listening to your needs and market expectations, we work together to create tailored products that will help you achieve your business objectives and success.",
            "txt4-0": "FRENCH",
            "txt4-1": "LABORATORY",
            "txt4-2": "INDEPENDENT",
            "txt4-3": "& AUTONOMOUS",
            "txt4-4": "RESEARCH &",
            "txt4-5": "DEVELOPMENT",
            "txt4-6": "+20 YEARS",
            "txt4-7": "OF EXPERIENCE",
            "txt5-0": "FORMULATION",
            "txt5-1": "& SOURCING",
            "txt5-2": "R&D EXPERTISE",
            "txt5-3": "MARKETING",
            "txt5-4": "MANUFACTURING",
            "txt5-5": "SMALL & LARGE SERIES",
            "txt5-6": "PACKAGING",
            "txt5-7": "OPTIMIZATION",
            "txt5-8": "TRAINING",
            "txt5-9": "& COMMUNICATION",
            "txt5-10": "DESIGN",
            "txt5-11": "& PACKAGING",
            "txt5-12": "REGULATION",
            "txt5-13": "& QUALITY",
            "txt6-0": "PRIVATE",
            "txt6-1": "LABEL",
            "txt6-2": "FORMULATION",
            "txt6-3": "You gain access to ready-to-market products.",
            "txt6-4": "CUSTOMIZED",
            "txt6-5": "FORMULATION",
            "txt6-6": "TAILOR-MADE",
            "txt6-7": "We develop formulas according to your specifications."
        },
        "NotreLabo": {
            "txt0-0": "YOUR DIETARY",
            "txt0-1": "SUPPLEMENTS",
            "txt0-2": "CREATOR",
            "txt1-0": "OUR HISTORY",
            "txt1-1": "Established in 2010, LIN (Natural Innovation Laboratory) is now one of the key players innovative dietary supplements in the world.",
            "txt1-2": "We have a research and development laboratory, a production facility, as well as a quality control system that ensures the traceability of raw materials from their receipt to the production of finished items. We guarantee high quality products containing highly bioavailable active ingredients.",
            "txt1-3": "A team of 40 professionals is dedicated to this division to ensure the successful realization of your manufacturing projects.",
            "txt2-0": "OUR EXPERTISE",
            "txt3-0": "FORMULATION",
            "txt3-1": "& SOURCING",
            "txt3-2-0":"• Formulation of innovative ingredients",
            "txt3-2-1":"• Identification of the most effective active ingredients",
            "txt3-2-2":"• Analysis of clinical studies",
            "txt3-2-3":"• Selection of rigorously chosen ingredients",
            "txt3-2-4":"• High standards of technicality and effectiveness", 
            "txt3-2-5":"• Customized or turnkey formulations",
            "txt3-2": "Formulation of innovative ingredients, Identification of the most effective active compounds, Analysis of clinical studies, Selection of rigorously chosen ingredients, Technical and efficient requirements, Custom or turnkey formulation.",
            "txt3-3": "REGULATORY",
            "txt3-4": "& SOURCING",
            "txt3-5-0":"• Health claims",
            "txt3-5-1":"• Regulatory verification of food supplements",
            "txt3-5-2":"• Validation of formulas and packaging", 
            "txt3-5-3":"• Analysis of recommended, methods and quality standards", 
            "txt3-5-4":"• Regulatory monitoring and watch",
            "txt3-5-5":"• Relations with control authorities (DGCCRF, ANSES, ANSM)",
            "txt3-5": "Health claims, Regulatory verification of dietary supplements, Validation of formulas and packaging, Analysis of method recommendations and quality standards, Regulatory monitoring and surveillance, Relationship with control authorities (DGCCRF, ANSES, ANSM).",
            "txt3-6": "MANUFACTURING &",
            "txt3-7": "PACKAGING",
            "txt3-8-0":"• Complete production tool for small and large production runs",
            "txt3-8-1":"• Micro encapsulation", 
            "txt3-8-2":"• Wide range of products: phytotherapy, nutraceuticals, probiotics, organic...", 
            "txt3-8-3":"• White-label production thanks to our dedicated catalog", 
            "txt3-8-4":"• Distribution to distribution centers (pallets) or directly to points of sale (packaging)",
            "txt3-8": "Complete production facility suitable for small and large series, Microencapsulation, Wide range of products: herbal medicine, nutraceuticals, probiotics, organic... White-label production thanks to our dedicated catalog, Distribution in distribution centers (pallets) or directly to point of sale (packaging).",
            "txt3-9": "TRAINING",
            "txt3-10": "& COMMUNICATION",
            "txt3-11-0":"• Training and communication", 
            "txt3-11-1":"• Development of sales tools for sales representatives",
            "txt3-11-2":"• Remote product sales", 
            "txt3-11-3":"• Sales team training",
            "txt3-11": "Training and communication, development of sales tools for sales representatives, remote product sales, sales team training.",
            "txt4-0": "OUR FACILITIES",
            "txt4-1": "Production flexibility to meet your needs:",
            "txt4-2": "batch sizes from 500 to 50,000 units!",
            "txt5-0": "PRODUCTION",
            "txt5-1": "3 mixers",
            "txt5-2": "- Capacity of 20 kilograms to 1 ton per day -",
            "txt5-3": "6 capsule machines",
            "txt5-4": "- Capacity of 200,000 capsules per hour -",
            "txt5-5": "6 capsule polishers",
            "txt5-6": "1 liquid filler",
            "txt5-7": "- From 10 to 30 ml -",
            "txt6-0": "PACKAGING",
            "txt6-1": "1 pill bottling line with capping and labeling for glass and plastic bottles",
            "txt6-2": "- Capacity of 10,000 units per day -",
            "txt6-3": "1 automated blister packaging line",
            "txt6-4": "- Capacity of 60,000 units per day -",
            "txt6-5": "2 automatic cartoners",
            "txt7-0": "QUALITY",
            "txt7-1": "Guaranteed quality control",
            "txt7-2": "- Holds multiple certifications -",
            "txt7-3": "(ORGANIC, HACCP, GMP FOOD...)",
            "txt7-4": "ISO 22000 standard project",
            "txt7-5": "Since late 2022, we have undertaken the necessary steps to obtain the ISO 22000 standard."
        },
        "SurMesure": {
            "txt0-0": "CUSTOMIZED FORMULAS",
            "txt0-1": "ON DEMAND",
            "txt0-2": "YOUR FORMULA",
            "txt0-3": "100% CUSTOMED",
            "txt0-4": "YOUR PACKAGING",
            "txt1-0": "You develop your formulas according to your specifications.",
            "txt1-1": "From concept to realization of your product, we provide you with our expertise to create fully customized formulations and packaging that are perfectly tailored to your target audience.",
            "txt1-2": "We offer a wide variety of products developed by our research and development team to meet the diverse needs of the market.",
            "txt1-3": "We provide personalized support with our entire team until your product is launched on the market.",
            "txt2-0": "OUR MANUFACTURING",
            "txt2-1": "PROCESSES",
            "txt3-0": "SPECIFICATIONS",
            "txt3-1": "DEVELOPMENT",
            "txt3-2": "PRODUCTION",
            "txt3-3": "PACKAGING",
            "txt3-4": "CUSTOMIZED",
            "txt3-5": "SHIPPING",
            "txt3-6": "PRODUCT",
            "txt3-7": "SHIPPING",
            "txt4-0": "OUR VARIOUS",
            "txt4-1": "PHARMACEUTICAL FORMS",
            "txt4-2": "POWDER",
            "txt4-3": "We custom powders packages according to your needs, whether it's herbs, vitamins, minerals, in combination or separately.",
            "txt4-4": "CAPSULE",
            "txt4-5": "We offer a complete range of sizes in vegetable version (pullulan, HPMC...) or gelatin.",
            "txt4-6": "CAPSULE",
            "txt4-7": "We offer a wide range of opportunities, whether for standard formulas or custom developments.",
            "txt4-8": "LIQUID",
            "txt4-9": "We also offer a range of vegetable oils or essential oils.",
            "txt5-0": "AND OUR",
            "txt5-1": "PACKAGING OPTIONS",
            "txt5-2": "(IN VARIOUS FORMATS)",
            "txt5-3": "Blister",
            "txt5-4": "Pill bottle",
            "txt5-5": "Bottle",
            "txt5-6": "Jar",
            "txt6-0": "OUR",
            "txt6-1": "APPLICATION",
            "txt6-2": "AREAS",
            "txt7-0": "BEAUTY",
            "txt7-1": "ORGANIC",
            "txt7-2": "PAIN RELIEF",
            "txt7-3": "CIRCULATION",
            "txt7-4": "URINARY COMFORT",
            "txt7-5": "COMFORT & PREVENTION",
            "txt7-6": "CIRCULATION",
            "txt7-7": "LIPOSOME",
            "txt7-8": "COMFORT & PREVENTION",
            "txt7-9": "TONICS & VITAMINS",
            "txt7-10": "DIGESTIVE HEALTH / DETOX",
            "txt7-11": "SLEEP & SERENITY",
            "txt7-12": "WOMEN'S RANGE",
            "txt7-13": "RESPIRATORY & IMMUNE SYSTEM",
            "txt7-14": "REQUEST",
            "txt7-15": "OUR CATALOG"
        },
        "MarqueBlanche": {
            "txt0-0": "OUR WHITE LABEL",
            "txt0-1": "FORMULAS",
            "txt0-2": " ",
            "txt0-3": "OUR FORMULA",
            "txt0-4": "FROM",
            "txt0-5": "OUR CATALOG",
            "txt0-6": "YOUR PACKAGING",
            "txt1-0": "We have a wide choice of innovative formulas. These formulations were developed by our research and development teams to meet the various needs of the market.",
            "txt1-1": "You have access to turnkey products ready to be launched on the market.",
            "txt1-2": "The minimum order threshold for this offer is 500 units. This service can complement our custom development services to ensure that your brand maintains its unique character.",
            "txt2-0": "OUR MANUFACTURING",
            "txt2-1": "PROCESSES",
            "txt2-2": "CHOOSING A",
            "txt2-3": "STANDARD FORMULA",
            "txt2-4": "PRODUCTION",
            "txt2-5": "CUSTOMIZED",
            "txt2-6": "PACKAGING ",
            "txt2-7": "SHIPPING",
            "txt2-8": "PRODUCT",
            "txt3-0": "OUR VARIOUS",
            "txt3-1": "PHARMACEUTICAL FORMS...",
            "txt3-2": "Powder",
            "txt3-3": "We custom powders packages according to your needs, whether it's herbs, vitamins, minerals, in combination or separately.",
            "txt3-4": "Capsule",
            "txt3-5": "We offer a complete range of sizes in vegetable version (pullulan, HPMC...) or gelatin.",
            "txt3-6": "Capsule",
            "txt3-7": "We offer a wide range of opportunities, whether for standard formulas or custom developments.",
            "txt3-8": "Liquid",
            "txt3-9": "We also offer a range of vegetable oils or essential oils.",
            "txt4-0": "AND OUR",
            "txt4-1": "PACKAGING OPTIONS",
            "txt4-2": "(IN VARIOUS FORMATS)",
            "txt4-3": "Blister",
            "txt4-4": "Pill bottle",
            "txt4-5": "Bottle",
            "txt4-6": "Jar",
            "txt5-0": "OUR",
            "txt5-1": "APPLICATION",
            "txt5-2": "AREAS",
            "txt6-0": "BEAUTY",
            "txt6-1": "ORGANIC",
            "txt6-2": "PAIN RELIEF",
            "txt6-3": "CIRCULATION",
            "txt6-4": "URINARY COMFORT",
            "txt6-5": "COMFORT & PREVENTION",
            "txt6-6": "CIRCULATION",
            "txt6-7": "LIPOSOME",
            "txt6-8": "COMFORT & PREVENTION",
            "txt6-9": "TONICS & VITAMINS",
            "txt6-10": "DIGESTIVE HEALTH / DETOX",
            "txt6-11": "SLEEP & SERENITY",
            "txt6-12": "WOMEN'S RANGE",
            "txt6-13": "RESPIRATORY & IMMUNE SYSTEM",
            "txt7": "Do you want to create and market new products with concepts already developed and approved in the market?",
            "txt7-14": "REQUEST",
            "txt7-15": "OUR CATALOG"   
        },
        "Qualite": {
            "txt0-0": "QUALITY",
            "txt0-1": "COMMITMENT",
            "txt0-2": "CERTIFIED",
            "txt1-0": "Our commitment to quality is constant and paramount.",
            "txt1-1": "The selection of materials according to our defined specifications is rigorous",
            "txt1-2": "and each finished product is analyzed according to an established control plan.",
            "txt1-3": "Production processes are established in compliance with applicable standards,",
            "txt1-4": "Quality of raw materials guaranteed through the selection of trusted suppliers,",
            "txt1-5": "Quality control at each stage of production, ensuring complete traceability,",
            "txt1-6": "Strict adherence to specifications.",
            "txt2-0": "RESEARCH",
            "txt2-01": " &",
            "txt2-1": "DEVELOPMENT",
            "txt2-2": "Assistance in formulation creation.",
            "txt2-3": "Research for high-performance raw materials.",
            "txt2-4": "Presentation of various possible formulations.",
            "txt2-5": "REGULATION",
            "txt2-6": "Our internal regulatory team ensures product compliance with French and European legislation.",
            "txt2-7": "Assistance in label drafting.",
            "txt2-8": "Declaration to the DGCCRF (General Directorate for Competition, Consumer Affairs and Fraud Control) and technical support in case of verification.",
            "txt2-9": "Registration with our certifying body for organic products.",
            "txt2-10": "QUALITY",
            "txt2-11": "Guarantee of product quality and safety (through our HACCP plan).",
            "txt2-12": "Complete traceability of materials and products.",
            "txt2-13": "Strict adherence to specifications.",
            "txt2-14": "Control performed at each stage of production.",
            "txt2-15": "Compilation of batch files.",
            "txt2-16": "* Regulatory texts based on EC regulations n°852/2004, EC n°178/2002, EC n°853/2004, the Codex Alimentarius, the recommended international code of practice."
        },
        "Other": {
            "txt0-0": "READ MORE+"
        },
        "Contact": {
            "txt0-0": "Mail sent successfully!",
            "txt0-1": "CONTACT US",
            "txt0-2": "Subject",
            "txt0-3": "Name",
            "txt0-4": "Company",
            "txt0-5": "Country",
            "txt0-6": "Phone",
            "txt0-7": "Email",
            "txt0-8": "Your inquiry",
            "txt0-9": "Send",
            "txt0-10": "Select a country"
        }
        
    },
    "中国人": {
        "Header": {
            "txt0-0": "我们的",
            "txt0-1": "实验室",
            "txt1-0": "私人品牌",
            "txt1-1": "配方",
            "txt2-0": "定制",
            "txt2-1": "配方",
            "txt3-0": "质量",
            "txt4-0": "联系我们",
        },
        "Footer": {
            "txt0-0": "145 RUE DE LA MARBRERIE",
            "txt0-1": "LE SALAISON",
            "txt0-2": "Bât.12",
            "txt0-3": "145 RUE DE LA MARBRERIE",
            "txt0-4": "34740 VENDARGUES",
            "txt0-5": "+33 4 99 78 08 11",
            "txt1-0": "联系我们",
            "txt1-1": "主题",
            "txt1-2": "姓名",
            "txt1-3": "名字",
            "txt1-4": "公司",
            "txt1-5": "国家",
            "txt1-6": "电话",
            "txt1-7": "电子邮件",
            "txt1-8": "留言",
            "txt1-9": "发送"
        },
        "HomePage": {
            "txt0-0": "我们的专业知识",
            "txt0-1": "创造和塑造",
            "txt0-2": "您的膳食补充剂",
            "txt1-0": "我们的主要目标是提供广泛的膳食补充剂，以满足每个人的健康和预防需求，并以无可挑剔的品质保证产品的有效性。",
            "txt1-1": "我们的产品设计基于两个元素的结合：",
            "txt1-2": "• 自然界提供的好处。",
            "txt1-3": "• 科学的相关进展。",
            "txt2-0": "我们提供生产的灵活性，从小规模到大规模，无论是私人品牌还是定制解决方案。",
            "txt2-1": "我们产品的质量由我们广泛的认证范围来保证。",
            "txt2-2": "我们提供多种剂型选择，无论是干燥剂型、胶囊、软胶囊还是液体，所有产品都具有卓越的质量。",
            "txt2-3": "我们的原材料经过精心挑选，以满足所有要求，并且我们的方法环保可持续。",
            "txt3-0": "我们的任务",
            "txt3-1": "我们倾听您的需求和市场期望，与您共同创造适合的产品，以实现您的商业目标和成功。",
            "txt4-0": "法国",
            "txt4-1": "独立",
            "txt4-2": "与自主性",
            "txt4-3": "研究与",
            "txt4-4": "开发",
            "txt4-5": "+20 年的经验",
            "txt5-0": "配方",
            "txt5-1": "& 采购",
            "txt5-2": "专业研发技术",
            "txt5-3": "澳大利亚市场营销",
            "txt5-4": "制造",
            "txt5-5": "小批量和大批量生产",
            "txt5-6": "包装",
            "txt5-7": "最佳化",
            "txt5-8": "培训",
            "txt5-9": "& 沟通",
            "txt5-10": "设计",
            "txt5-11": "& 包装",
            "txt5-12": "法规",
            "txt5-13": "& 质量",
            "txt6-0": "私人品牌",
            "txt6-1": "配方",
            "txt6-2": "您获得市场就绪的产品。",
            "txt6-3": "定制配方",
            "txt6-4": "量身定制",
            "txt6-5": "您根据您的规格开发配方。"
        },
        "NotreLabo": {
            "txt0-0": "创造者",
            "txt0-1": "你的补充",
            "txt0-2": "食物",
            "txt1-0": "我们的历史",
            "txt1-1": "成立于2010年，LIN（Natural Innovation Laboratory）现在是膳食补充剂领域的重要参与者之一。",
            "txt1-2": "我们拥有研发实验室、生产设施和质量控制系统，确保从原材料接收到成品生产的可追溯性。我们保证高质量的产品同时保留活性成分的特性。",
            "txt1-3": "一个由40名专业人员组成的团队致力于这个部门，以确保您的生产项目成功实现。",
            "txt2-0": "我们的专业知识",
            "txt3-0": "配方",
            "txt3-1": "和采购",
            "txt3-2-0":"• 配制创新成分",
            "txt3-2-1":"• 确定表现最佳的资产",
            "txt3-2-2":"• 临床研究分析",
            "txt3-2-3":"• 精选食材",
            "txt3-2-4":"• 要求专业技术和效率", 
            "txt3-2-5":"• 量身定制或交钥匙配方。",
            "txt3-2": "创新成分的配方，最有效活性化合物的鉴定，临床研究的分析，严格选择的成分，技术和高效的要求，定制或一揽子配方。",
            "txt3-3": "法规",
            "txt3-4": "和采购",
            "txt3-5-0":"• 健康索赔",
            "txt3-5-1":"• 对食品补充剂的监管检查",
            "txt3-5-2":"• 验证配方和包装", 
            "txt3-5-3":"• 分析质量建议、方法和标准", 
            "txt3-5-4":"• 监测和监管观察",
            "txt3-5-5":"• 与监管机构（DGCCRF、ANSES、ANSM）的关系。",
            "txt3-5": "健康声明，膳食补充剂的法规验证，配方和包装的验证，方法建议和质量标准的分析，法规监测和监察，与监管机构（DGCCRF，ANSES，ANSM）的联系。",
            "txt3-6": "生产和",
            "txt3-7": "包装",
            "txt3-8-0":"• 适用于小批量和大批量生产的全套生产工具",
            "txt3-8-1":"• 微型封装", 
            "txt3-8-2":"• 产品范围选择广泛：植物疗法、营养保健品、益生菌、有机食品、食品添加剂、食品添加剂、食品添加剂、食品添加剂、食品添加剂、食品添加剂、食品添加剂...", 
            "txt3-8-3":"• 使用我们的专用目录进行白标生产", 
            "txt3-8-4":"• 配送至配送中心（托盘）或直接配送至销售点（包装）",
            "txt3-8": "适用于小规模和大规模的完整生产设施，微囊化，广泛的产品范围：草药、保健品、益生菌、有机产品...凭借我们专用的目录进行白标生产，配送到配送中心（托盘）或直接到销售点（包装）。",
            "txt3-9": "培训和",
            "txt3-10": "沟通",
            "txt3-11-0":"• 培训与交流", 
            "txt3-11-1":"• 为销售代表开发销售工具",
            "txt3-11-2":"• 远程销售", 
            "txt3-11-3":"• 销售团队培训",
            "txt3-11": "培训和沟通，开发销售代表的销售工具，远程产品销售，销售团队培训。",
            "txt4-0": "我们的设施",
            "txt4-1": "生产灵活性，满足您的需求：",
            "txt4-2": "批量从500个到50,000个单位！",
            "txt5-0": "生产",
            "txt5-1": "3台混合机",
            "txt5-2": "- 每天20千克到1吨的产能 -",
            "txt5-3": "6台胶囊机",
            "txt5-4": "- 每小时20万颗胶囊的产能 -",
            "txt5-5": "6台胶囊抛光机",
            "txt5-6": "1台液体灌装机",
            "txt5-7": "- 容量为10到30毫升 -",
            "txt6-0": "包装",
            "txt6-1": "1条药片灌装线，可用于玻璃瓶和塑料瓶的封瓶和贴标",
            "txt6-2": "- 每天10,000个单位的产能 -",
            "txt6-3": "1条自动制袋包装线",
            "txt6-4": "- 每天60,000个单位的产能 -",
            "txt6-5": "2台自动装箱机",
            "txt7-0": "质量",
            "txt7-1": "质量保证",
            "txt7-2": "— 拥有多项认证 —",
            "txt7-3": "（有机、HACCP、GMP FOOD…）",
            "txt7-4": "ISO 22000 标准项目",
            "txt7-5": "自 2022 年底起，我们已经采取了获得 ISO 22000 标准所需的步骤。"
        },
        "SurMesure": {
            "txt0-0": "定制配方",
            "txt0-1": "按需求",
            "txt0-2": "您的定制",
            "txt0-3": "100% 配方",
            "txt0-4": "个性化",
            "txt1-0": "根据您的规格开发配方。",
            "txt1-1": "从产品概念到实现，我们将提供专业知识，为您创建完全定制的配方和包装，以完美迎合您的目标受众。",
            "txt1-2": "我们提供多种产品，由我们的研发团队开发，以满足市场的多样需求。",
            "txt1-3": "我们的整个团队将提供个性化支持，直到您的产品在市场上推出。",
            "txt2-0": "我们的制造",
            "txt2-1": "流程",
            "txt3-0": "规格",
            "txt3-1": "开发",
            "txt3-2": "生产",
            "txt3-3": "包装",
            "txt3-4": "定制",
            "txt3-5": "发货",
            "txt3-6": "产品",
            "txt3-7": "船运",
            "txt4-0": "我们的各种",
            "txt4-1": "药品形式",
            "txt4-2": "粉末",
            "txt4-3": "我们根据您的需求定制包装粉末，无论是草药、维生素、矿物质，还是其组合或分离使用。",
            "txt4-4": "胶囊",
            "txt4-5": "我们提供完整尺寸范围的胶囊，无论是明胶还是素食版本。我们提供O号或1号尺寸的胶囊。",
            "txt4-6": "胶囊",
            "txt4-7": "无论是标准配方还是定制开发，我们提供广泛的选择。",
            "txt4-8": "液体",
            "txt4-9": "我们还提供一系列植物油或精油。",
            "txt5-0": "以及我们的",
            "txt5-1": "包装选项",
            "txt5-2": "（以各种格式）",
            "txt5-3": "泡罩",
            "txt5-4": "药片瓶",
            "txt5-5": "瓶子",
            "txt5-6": "罐子",
            "txt6-0": "我们的",
            "txt6-1": "应用领域",
            "txt6-2": "",
            "txt7-0": "美容",
            "txt7-1": "有机的",
            "txt7-2": "止痛",
            "txt7-3": "循环",
            "txt7-4": "尿路舒适",
            "txt7-5": "舒适与预防",
            "txt7-6": "循环",
            "txt7-7": "脂质体",
            "txt7-8": "舒适与预防",
            "txt7-9": "强身健体与维生素",
            "txt7-10": "消化健康/排毒",
            "txt7-11": "睡眠与宁静",
            "txt7-12": "女性系列",
            "txt7-13": "呼吸与免疫",
            "txt7-14": "问",
            "txt7-15": "我们的目录"
        },
        "MarqueBlanche": {
            "txt0-0": "我们的白标",
            "txt0-1": "配方",
            "txt0-2": "白标",
            "txt0-3": "我们的配方",
            "txt0-4": "来自",
            "txt0-5": "我们的目录",
            "txt0-6": "您的包装",
            "txt1-0": "我们拥有广泛的“标准配方”。这些配方是由我们的研发团队开发，以满足市场的多样需求。",
            "txt1-1": "您可以访问已经准备好在市场上推出的即插即用产品。",
            "txt1-2": "此服务的最低订单数量为500个单位。这项服务可以与我们的定制开发服务相结合，以确保您的品牌保持其独特性。",
            "txt2-0": "我们的制造",
            "txt2-1": "流程",
            "txt2-2": "选择一个",
            "txt2-3": "标准配方",
            "txt2-4": "生产",
            "txt2-5": "包装",
            "txt2-6": "个性化",
            "txt2-7": "发货",
            "txt2-8": "产品",
            "txt3-0": "我们的各种",
            "txt3-1": "药品形式...",
            "txt3-2": "粉末",
            "txt3-3": "我们根据您的需求定制包装粉末，无论是草药、维生素、矿物质，还是其组合或分离使用。",
            "txt3-4": "胶囊",
            "txt3-5": "我们提供完整尺寸范围的胶囊，无论是明胶还是素食版本。我们提供O号或1号尺寸的胶囊。",
            "txt3-6": "胶囊",
            "txt3-7": "无论是标准配方还是定制开发，我们提供广泛的选择。",
            "txt3-8": "液体",
            "txt3-9": "我们还提供一系列植物油或精油。",
            "txt4-0": "以及我们的",
            "txt4-1": "包装选项",
            "txt4-2": "（以各种格式）",
            "txt4-3": "泡罩",
            "txt4-4": "药片瓶",
            "txt4-5": "瓶子",
            "txt4-6": "罐子",
            "txt5-0": "我们的",
            "txt5-1": "应用领域",
            "txt5-2": "",
            "txt6-0": "美容",
            "txt6-1": "有机的",
            "txt6-2": "止痛",
            "txt6-3": "循环",
            "txt6-4": "尿路舒适",
            "txt6-5": "舒适与预防",
            "txt6-6": "循环",
            "txt6-7": "脂质体",
            "txt6-8": "舒适与预防",
            "txt6-9": "强身健体与维生素",
            "txt6-10": "消化健康/排毒",
            "txt6-11": "睡眠与宁静",
            "txt6-12": "女性系列",
            "txt6-13": "呼吸与免疫",
            "txt7": "您想要使用市场上已经开发并获得批准的概念来创建并推广新产品吗",
            "txt7-14": "问",
            "txt7-15": "我们的目录"
        },
        "Qualite": {
            "txt0-0": "质量",
            "txt0-1": "承诺",
            "txt0-2": "认证",
            "txt1-0": "我们对质量的承诺是持续而重要的。",
            "txt1-1": "根据我们定义的规格选择材料是严格的。",
            "txt1-2": "每个成品都按照既定的控制计划进行分析。",
            "txt1-3": "生产流程符合适用的标准。",
            "txt1-4": "通过选择可靠供应商来保证原材料的质量。",
            "txt1-5": "在生产的每个阶段进行质量控制，确保完全可追溯性。",
            "txt1-6": "严格遵守规格。",
            "txt2-0": "研究与",
            "txt2-01": " &",
            "txt2-1": "开发",
            "txt2-2": "协助制定配方。",
            "txt2-3": "寻找高性能原材料。",
            "txt2-4": "提供各种可能的配方。",
            "txt2-5": "法规",
            "txt2-6": "我们内部的法规团队确保产品符合法国和欧洲的法规。",
            "txt2-7": "协助制定标签。",
            "txt2-8": "向DGCCRF（竞争、消费者事务和欺诈控制总局）申报，并在核查时提供技术支持。",
            "txt2-9": "向我们的有机产品认证机构注册。",
            "txt2-10": "质量",
            "txt2-11": "产品质量和安全的保证（通过我们的HACCP计划）。",
            "txt2-12": "材料和产品的完全可追溯性。",
            "txt2-13": "严格遵守规格。",
            "txt2-14": "在生产的每个阶段进行控制。",
            "txt2-15": "批次文件的编制。",
            "txt2-16": "* 根据欧共体第 n°852/2004 号条例、欧共体第 n°178/2002 号条例、欧共体第 n°853/2004 号条例、食品法典、建议的国际业务守则制定的法规文本。"
        },
        "Other": {
            "txt0-0": "了解更多"
        },
        "Contact": {
            "txt0-0": "邮件发送成功！",
            "txt0-1": "联系我们",
            "txt0-2": "主题",
            "txt0-3": "姓名",
            "txt0-4": "公司",
            "txt0-5": "国家",
            "txt0-6": "电话",
            "txt0-7": "邮箱",
            "txt0-8": "您的咨询",
            "txt0-9": "发送",
            "txt0-10": "选择一个国家"
        }
        
    }
}

export default global;